import React, { createContext, useState, useRef, useEffect } from 'react';
import { fetchData } from '../api/api'; 
import { supabase } from '../utils/supabase';

const DataContext = createContext();

const DataProvider = ({ children }) => {
    const [state, setState] = useState({
        aboutData: {},
        skillsData: [],
        projectsData: {
            projects: [],
            portfolio: [],
            study: []
        },
        reviewsData: [],
        loading: {
            about: true,
            skills: true,
            projects: true,
            reviews: true
        },
        error: null,
    });

    const fetchExecuted = useRef({ about: false, skills: false, projects: false, reviews: false });

    useEffect(() => {
        const fetchAboutData = async () => {
            try {
            const response = await fetchData('about');
            setState(prevState => ({
                ...prevState,
                aboutData: response.data,
                loading: { ...prevState.loading, about: false },
            }));
            } catch (err) {
            setState(prevState => ({
                ...prevState,
                error: prevState.error ? prevState.error : 'Error fetching about data',
                loading: { ...prevState.loading, about: false },
            }));
            }
        };

        const fetchSkillsData = async () => {
            try {
                const response = await supabase.from('categories').select();
                setState(prevState => ({
                    ...prevState,
                    skillsData: response.data,
                    loading: { ...prevState.loading, skills: false },
                }));
            } catch (err) {
                setState(prevState => ({
                    ...prevState,
                    error: prevState.error ? prevState.error : 'Error fetching skills data',
                    loading: { ...prevState.loading, skills: false },
                }));
            }
        };

        const fetchProjectsData = async () => {
            try {                
                let { data: response, error } = await supabase.from('projects').select().range(0, 12);
                
                if (error) {
                    console.error('Erro ao buscar projetos:', error);
                    return;
                }
                
                response = await Promise.all(
                    response.map(async (project) => {                        
                        let { data: projectsCategories, error: categoryError } = await supabase
                            .from('projects_categories')
                            .select('category_id')
                            .eq('project_id', project.id);
                        
                        if (categoryError) {
                            console.error('Erro ao buscar categorias:', categoryError);
                            return {
                                ...project,
                                categories: []
                            };
                        }
                        
                        return {
                            ...project,
                            categories: projectsCategories.map(rel => rel.category_id)  || []
                        };
                    })
                );

                let projects = response.slice(0, 4);
                let portfolioData = response.filter(item => item.project_type === 'portfolio').slice(0, 4);
                let studiesData = response.filter(item => item.project_type === 'estudo').slice(0, 4);
                
                setState(prevState => ({
                    ...prevState,
                    projectsData: {
                        projects: projects,
                        portfolio: portfolioData,
                        study: studiesData
                    },
                    loading: { ...prevState.loading, projects: false },
                }));
                
            } catch (err) {
                setState(prevState => ({
                    ...prevState,
                    error: prevState.error ? prevState.error : 'Error fetching Projects data',
                    loading: { ...prevState.loading, projects: false },
                }));
            }
        };

        const fetchReviewsData = async () => {
            try {
                const response = await supabase.from('reviews').select();
                setState(prevState => ({
                ...prevState,
                reviewsData: response.data,
                loading: { ...prevState.loading, reviews: false },
                }));
            } catch (err) {
                setState(prevState => ({
                    ...prevState,
                    error: prevState.error ? prevState.error : 'Error fetching Reviews data',
                    loading: { ...prevState.loading, reviews: false },
                }));
            }
        };

        if (!fetchExecuted.current.about) {
            fetchAboutData();
            fetchExecuted.current.about = true;
        }

        if (!fetchExecuted.current.skills) {
            fetchSkillsData();
            fetchExecuted.current.skills = true;
        }

        if (!fetchExecuted.current.projects) {
            fetchProjectsData();
            fetchExecuted.current.projects = true;
        }

        if (!fetchExecuted.current.reviews) {
            fetchReviewsData();
            fetchExecuted.current.reviews = true;
        }

    }, []);

    const getProjectBySlug = (slug) => {
        return (
            state.projectsData.portfolio.find(project => project.slug === slug) ||
            state.projectsData.study.find(project => project.slug === slug)
        );
    }

    const getCategoriesById = (categories) => {
        return (
            state.skillsData.filter(category => categories.includes(category.id))
        )
    }

    const getCategoryBySlug = (categories) => {
        return (
            state.skillsData.filter(category => categories.includes(category.slug))[0]
        )
    }

    const getReviewsByProjectId = (projectId) => {
        return (
            state.reviewsData.filter(review => review.project_id === projectId)
        )
    }

    return (
        <DataContext.Provider value={{ state, setState, getProjectBySlug, getCategoriesById, getCategoryBySlug, getReviewsByProjectId }}>
        {children}
        </DataContext.Provider>
    );
};

export { DataContext, DataProvider };
